<template>
    <div class="login">

        <div id="app">
            <v-app id="inspire">
                <v-content>
                    <v-container
                            fluid
                            fill-height
                    >
                        <v-layout
                                align-center
                                justify-center
                        >
                            <v-flex
                                    xs12
                                    sm8
                                    md4
                            >
                                <v-card class="elevation-12">
                                    <v-toolbar
                                            color="primary"
                                            dark
                                            flat
                                    >
                                        <v-toolbar-title>Cinema Accademia</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <v-card-text>
                                        <v-form>
                                            <v-text-field
                                                    label="Login"
                                                    name="login"
                                                    prepend-icon="mdi-account-outline"
                                                    v-model="email"
                                                    type="text"
                                            ></v-text-field>

                                            <v-text-field
                                                    id="password"
                                                    label="Password"
                                                    name="password"
                                                    v-model="password"
                                                    prepend-icon="mdi-lock-outline"
                                                    type="password"
                                            ></v-text-field>
                                            <v-checkbox v-model="remember" label="Ricordami"></v-checkbox>
                                        </v-form>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn v-on:click="login" color="primary">Login</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-content>
            </v-app>
        </div>
    </div>
</template>

<script>
    import db from "../firebaseConfiguration/firebaseInit";

    export default {
        name: 'login',
        data: function () {
            return {
                email: '',
                password: '',
                remember:false,
            }
        },
        methods: {
            login() {
                db.auth().signInWithEmailAndPassword(this.email, this.password).then((user) => {
                    let auth = {name: this.email, password: this.password}
                    this.$store.commit("setAuth",auth);
                    this.$router.replace('/admin')
                    if(this.remember)
                    this.setCookie("auth", JSON.stringify(auth),30);
                }).catch((err) => {
                    alert(err.message)
                })
            },
            setCookie(cname, cvalue, exdays) {
                var d = new Date();
                d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
            },
            getCookie(cname)
            {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {

                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }

        },
        mounted() {
                let cookie = this.getCookie("auth");
                let parse = JSON.parse(cookie);
            if(parse != ""){
            db.auth().signInWithEmailAndPassword(parse.name, parse.password).then((user) => {
                let auth = {name: this.email, password: this.password}
                this.$store.commit("setAuth",JSON.stringify(auth));
                this.$router.replace('/admin')
            }).catch((err) => {
                alert(err.message)
            })

        }}
    }
</script>

<style scoped>

</style>